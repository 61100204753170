import Extractor from "@jordibosch20/software_tools_package/dist/pages/Extractor.component.js"
import styles from "./App.module.css";
import { getPhoneNumber } from "@jordibosch20/software_tools_package/dist/utils/phone-number-extractor.js"
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <Extractor title={"Mobile number extractor"} f={getPhoneNumber}></Extractor>
      </div>
      <div className={styles.text}>
        <p><strong>Introduction</strong></p>
        <p>In an age where time is as valuable as the data we process, having the right tools to efficiently manage and extract crucial information is imperative. The Phone Number Extractor emerges as a beacon of efficiency for professionals across various sectors&mdash;marketing, sales, customer service, and more. This innovative tool is designed to sift through any given text and meticulously extract phone numbers, saving hours of manual labor and significantly reducing the margin for error.</p>
        <p><strong>Why Use a Phone Number Extractor?</strong></p>
        <p>Extracting phone numbers from large volumes of text can be a daunting and time-consuming task. Whether you're dealing with customer feedback, sales leads, or any other type of data, the need to swiftly identify and compile phone numbers is a common challenge. Manual extraction not only eats into valuable time but also poses a risk of inaccuracies. This is where a dedicated phone number extractor tool comes into play, automating the process with precision and speed.</p>
        <p><strong>Key Features of Our Phone Number Extractor</strong></p>
        <ul>
        <li><strong>Accuracy and Reliability</strong>: Ensures that phone numbers are accurately identified and extracted from a wide range of text formats.</li>
        <li><strong>Speed</strong>: Processes large volumes of text in seconds, significantly cutting down the time required for data extraction tasks.</li>
        <li><strong>User-Friendly Interface</strong>: Designed with simplicity in mind, it requires no technical expertise to operate.</li>
        <li><strong>Versatility</strong>: Capable of extracting numbers from text across various sources&mdash;documents, web pages, and more.</li>
        <li><strong>Security</strong>: Prioritizes the privacy and security of your data, ensuring that information processed through the tool remains confidential.</li>
        </ul>
        <p><strong>How It Works</strong></p>
        <p>The process is straightforward:</p>
        <ol>
        <li>Input the text from which you need to extract phone numbers.</li>
        <li>Initiate the extraction process with a simple click.</li>
        <li>Receive a list of accurately extracted phone numbers.</li>
        </ol>
        <p>This simplicity ensures that anyone, regardless of their technical skill level, can efficiently utilize the tool to enhance their data processing tasks.</p>
        <p><strong>Applications and Benefits</strong></p>
        <ul>
        <li><strong>Marketing and Sales</strong>: Quickly compile lists of customer contact numbers from various data sources for outreach campaigns.</li>
        <li><strong>Customer Service</strong>: Extract phone numbers from customer communications for follow-up calls or service verifications.</li>
        <li><strong>Data Analysis</strong>: Easily gather contact information from datasets for research or analytical purposes.</li>
        <li><strong>Time and Resource Efficiency</strong>: Save hours of manual work, allowing professionals to focus on more strategic tasks.</li>
        </ul>
        <p><strong>Conclusion</strong></p>
        <p>The Phone Number Extractor stands out as an essential tool for anyone looking to optimize their data extraction processes. Its precision, speed, and user-friendly design make it a valuable asset for professionals aiming to enhance their productivity and data management practices. Embrace the efficiency of automated phone number extraction and redirect your focus to where it matters most&mdash;growing your business and serving your clients.</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;